import { Fetch } from 'lib/Fetch';

export function contactUsService({
  name, email, message, type,
}) {
  return new Fetch()
    .url('/portal/notification/contact-us')
    .method('post')
    .data({
      name, email, message, type,
    })
    .portalToken()
    .send();
}

export function createComplaints(form) {
  return new Fetch('api_4')
    .url('/external/global/sfc/complaint')
    .method('post')
    .data(form)
    .portalToken()
    .send();
}
export function sendAttachment(file) {
  return new Fetch('api_4')
    .url('/external/global/sfc/send-attachment')
    .method('post')
    .data(file)
    .portalToken()
    .send();
}
