import React, { useRef } from 'react';
import { colors, weight, Div } from 'globalStyle';
import uploadButton from 'assets/icons/uploadButton.svg';
import { Label, ErrorText } from 'components/Form';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { Text } from 'components/Texts';
import { CheckInputIcon, CloseIcon } from 'icons';
import { InputBox } from './styled';

export const InputFile = ({
  mb,
  title,
  subTitle,
  text,
  accept,
  onChange,
  error,
  fileName,
  onRemoveFile,

}) => {
  const ref = useRef(null);
  const renderInput = () => (fileName ? (
    <CheckInputIcon color={colors.success} width="1.5em" height="1.5em" />
  ) : (
    <File
      displayNone={!!fileName}
      onChange={onChange}
      ref={ref}
      type="file"
      accept={accept}
    />
  ));

  return (
    <Div mb={mb}>
      <Label title={title} subTitle={subTitle} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10} md={9}>
          <InputBox
            isOverflow
            isError={error && true}
            style={{
              cursor: 'initial',
              paddingRight: '3em',
              position: 'relative',
            }}
          >
            <Text
              number={4}
              color={colors.GRAY_2}
              weight={weight.REGULAR}
              isOverflow
            >
              {fileName || 'Cargar archivo'}
            </Text>
            {fileName && (
              <CloseIcon
                isClick
                onClick={onRemoveFile}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '0',
                }}
              />
            )}
          </InputBox>
        </Grid>
        <Grid item xs={12} md={2}>
          {renderInput()}
        </Grid>
      </Grid>
      {text && (
        <Text number={4} mt={5}>
          {text}
        </Text>
      )}
      <ErrorText text={error} />
    </Div>
  );
};

const File = styled.input`
  text-indent: -999em;
  background-image: url(${uploadButton});
  background-repeat: no-repeat;
  cursor: pointer;
  outline: none;
  border: none;
  height: 33px;
  width: 100px;
`;
